






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class GaiterProduct extends Vue {
    @Prop() private productName!: string;
    @Prop() private link!: string;
    @Prop() private price!: string;
}
